import React from "react"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import { ParallaxProvider } from "react-scroll-parallax"

const Main = ({ children }) => {
  return (
    <div className="layout--main">
      <ParallaxProvider>
        <NavBar></NavBar>
        {children}
        <Footer></Footer>
      </ParallaxProvider>
    </div>
  )
}

export default Main
