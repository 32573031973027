import React, { useState } from "react"
import { Link } from "gatsby"
import imgLogo from "../assets/images/logoV2.svg"
import imgBackToTop from "../assets/images/icons/BackToTop.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useInView } from "react-intersection-observer"

import { OutboundLink } from "gatsby-plugin-google-gtag"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  })
}
const Footer = () => {
  const [ref, inView] = useInView()

  const url =
    "https://cafekraken.us20.list-manage.com/subscribe/post?u=074845b45c436af6e1818e536&amp;id=1ffedf8597"

  const CustomForm = ({ status, message, onValidated }) => {
    const [birthday, setBirthday] = useState("")
    let email, name, day, month

    const submit = () => {
      day = birthday ? birthday.getUTCDate() : ""
      month = birthday ? birthday.getUTCMonth() + 1 : ""

      email &&
        name &&
        email.value.indexOf("@") > -1 &&
        onValidated({
          EMAIL: email.value,
          FNAME: name.value.split(/ (.+)/)[0],
          LNAME: name.value.split(/ (.+)/)[1],
          "BIRTHDAY[month]": month,
          "BIRTHDAY[day]": day,
        })
    }
    return (
      <div
        style={{
          display: "inline-block",
        }}
        className="w-100"
      >
        <div className="row pt-5">
          <div className="col-sm-12 col-lg-4">
            <input ref={node => (name = node)} type="text" placeholder="Name" />
          </div>

          <div className="col-sm-12 col-lg-4">
            <input
              ref={node => (email = node)}
              type="email"
              placeholder="Email"
              required
            />
          </div>

          <div className="col-sm-12 col-lg-4">
            <DatePicker
              selected={birthday}
              onChange={date => setBirthday(date)}
              maxDate={new Date()}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="Birthday"
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-sm-12 d-flex justify-content-end">
            <button
              onClick={submit}
              className="btn btn--ecru--white ml-auto my-4 d-block"
            >
              Sign Up
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 d-flex justify-content-end">
            <div className="text-right pt-3">
              {status === "sending" && (
                <div className="text-ecru-white">sending...</div>
              )}
              {status === "error" && (
                <div
                  className="text-ecru-white"
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              )}
              {status === "success" && (
                <div
                  className="text-ecru-white"
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              )}
            </div>
          </div>
        </div>

        <br />
      </div>
    )
  }

  return (
    <section
      className="footer"
      style={{
        transition: "opacity 1000ms",
        opacity: inView ? 1 : 0,
        transition: "all 1s",
      }}
      ref={ref}
    >
      <div className="footer--mailing-list">
        <div className="container">
          <h3 className="text-center py-3">
            Sign up to our mailing list and get a complimentary treat!
          </h3>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
              />
            )}
          />
        </div>
      </div>
      <div className="container">
        <div className="footer--wrapper">
          <div className="row">
            <div className="col-lg-6 col-12 footer--left">
              <Link to="/">
                <img src={imgLogo} className="footer__logo" alt="dk_logo" />
              </Link>
              <ul className="p-0 m-0">
                <li className="footer__list">
                  <Link to="/about" className="footer__link">
                    <h2>About</h2>
                  </Link>
                </li>
                <li className="footer__list">
                  <Link to="/suppliers" className="footer__link">
                    <h2>Suppliers</h2>
                  </Link>
                </li>
                <li className="footer__list">
                  <Link to="/blog" className="footer__link">
                    <h2>Blog</h2>
                  </Link>
                </li>
                <li className="footer__list">
                  <Link to="/contact" className="footer__link">
                    <h2>Contact</h2>
                  </Link>
                </li>
                <li className="footer__list">
                  <OutboundLink
                    href="https://shop.cafekraken.com/"
                    target="_blank"
                    className="footer__link"
                  >
                    <h2 className="cta">Order Now</h2>
                  </OutboundLink>
                </li>
              </ul>
            </div>

            <div className="col-lg-6 col-12 footer--right">
              <img
                src={imgBackToTop}
                alt="backToTop"
                onClick={scrollToTop}
                className="backtotop"
              />

              <h2 className="footer--email">
                <a href="mailto:hello@cafekraken.com">hello@cafekraken.com</a>
              </h2>
              <p className="footer--opening">
                Wednesday - Sunday 8.00am - 4.30pm
              </p>
            </div>
          </div>

          <div className="row pt-5">
            <div className="col-lg-6 col-12 copyright">
              <p>© 2022 Café Kraken - All Rights Reserved</p>
            </div>

            <div className="col-lg-6 col-12">
              <div className="footer--socials">
                <a
                  href="https://www.facebook.com/cafekrakenoxford/"
                  target="_blank"
                  className="px-1"
                >
                  <FontAwesomeIcon icon={["fab", "facebook"]} />
                </a>
                <a
                  href="https://www.instagram.com/cafekrakenuk/"
                  target="_blank"
                  className="px-1"
                >
                  <FontAwesomeIcon icon={["fab", "instagram"]} />
                </a>
                <a
                  href="https://twitter.com/cafekraken?lang=en"
                  target="_blank"
                  className="px-1"
                >
                  <FontAwesomeIcon icon={["fab", "twitter"]} />
                </a>
                <a
                  href="https://www.linkedin.com/company/cafe-kraken/"
                  target="_blank"
                  className="px-1"
                >
                  <FontAwesomeIcon icon={["fab", "linkedin"]} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
