import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"

import MainLayout from "../layouts/MainLayout"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Helmet } from "react-helmet"
import { GatsbyImage } from "gatsby-plugin-image"

const BlogPost = ({ data, pageContext }) => {
  const { next, prev } = pageContext

  console.log(data, pageContext)

  return (
    <MainLayout>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="C.Liu" />

        <title>
          {data.contentfulCafeKrakenBlog.title} - Blog | Cafe Kraken
        </title>
        <meta
          name="description"
          content={data.contentfulCafeKrakenBlog.snippet}
        />
        <meta
          name="image"
          content={data.contentfulCafeKrakenBlog.heroImage.fixed.src}
        />

        {data.contentfulCafeKrakenBlog.title && (
          <meta
            property="og:title"
            content={data.contentfulCafeKrakenBlog.title}
          />
        )}
        {data.contentfulCafeKrakenBlog.snippet && (
          <meta
            property="og:description"
            content={data.contentfulCafeKrakenBlog.snippet}
          />
        )}
        {data.contentfulCafeKrakenBlog.heroImage.fixed.src && (
          <meta
            property="og:image"
            content={data.contentfulCafeKrakenBlog.heroImage.fixed.src}
          />
        )}
      </Helmet>
      <section className="blog--post">
        <div className="container">
          <div className="blog--post__header">
            <div className="row d-flex align-items-center justify-content-center mt-5 pt-5 text-center">
              <GatsbyImage
                image={data.contentfulCafeKrakenBlog.heroImage.gatsbyImageData}
                alt={""}
                className="blog--post__header--banner"
              />
              <h6 className="font-weight-bold text-monte-carlo pt-3">
                {data.contentfulCafeKrakenBlog.publishDate}
              </h6>
            </div>
            <div className="row d-flex align-items-center justify-content-center">
              <h1 className="text-center py-4" id="post-title">
                {data.contentfulCafeKrakenBlog.title}
              </h1>
            </div>
            <div className="row d-flex align-items-center justify-content-center">
              <p className="blog--post__author font-weight-bold text-center">
                By {data.contentfulCafeKrakenBlog.author.firstName}{" "}
                {data.contentfulCafeKrakenBlog.author.lastName}
              </p>
            </div>
          </div>
          <div className="blog--post__content py-5">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  data.contentfulCafeKrakenBlog.body.childMarkdownRemark.html,
              }}
            />
          </div>
        </div>
      </section>
    </MainLayout>
  )
}

export default BlogPost

export const query = graphql`
  query ($slug: String) {
    contentfulCafeKrakenBlog(slug: { eq: $slug }) {
      author {
        lastName
        firstName
      }
      slug
      snippet
      title
      publishDate(formatString: "Do MMMM y")
      heroImage {
        gatsbyImageData
        fixed(width: 1600) {
          src
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
